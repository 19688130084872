import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import './TweetSection.scss';

function TweetsSection({ tweets, title = "Elon Musk about $media" }) {
    const renderTweets = () => {
        if (tweets.length === 3) {
            return (
                <Row>
                    {tweets.map((tweet, index) => (
                        <Col key={index} xs={12} md={4}>
                            <div className="tweet-item">
                                <TwitterTweetEmbed
                                    tweetId={tweet.id}
                                    options={{
                                        theme: "dark",
                                        align: "center",
                                        width: "100%",
                                        conversation: "none"
                                    }}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            );
        }

        return (
            <div className="tweets-masonry">
                {tweets.map((tweet, index) => (
                    <div key={index} className="tweet-item">
                        <TwitterTweetEmbed
                            tweetId={tweet.id}
                            options={{
                                theme: "dark",
                                align: "center",
                                width: "100%",
                                conversation: "none"
                            }}
                        />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <section className="tweets-section py-5 text-light">
            <Container>
                <h2 className="title mb-5 text-center">
                    <FontAwesomeIcon icon={faXTwitter} className="me-3" />
                    {title}
                </h2>
                <div className="mb-5">
                    {renderTweets()}
                </div>
            </Container>
        </section>
    );
}

export default TweetsSection;