import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HeaderComponent from '../components/Header/HeaderComponent';
import MediaMissionComponent from '../components/Mission/MediaMissionComponent';
import Roadmap from '../components/Roadmap/Roadmap';
import MediaTokenomics from '../components/Tokenomics/Tokenomics';
import MediaTokenBuySection from '../components/Buy/Buy';
import VideoSection from '../components/Video/VideoSection';
import TweetsSection from '../components/Social/TweetSection';
import { HEADER_CONTENT } from '../constants/content';
import vid from '../assets/vid.mp4';
import './Home.scss';

const tweets = [
    {
        id: "1862842627680350492"
    },
    {
        id: "1861115222783889589"
    },
    {
        id: "1862145513879134445"
    }
];

function Home() {
    const [showGiveaway, setShowGiveaway] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Show popup after 3 seconds
        const timer = setTimeout(() => {
            setShowGiveaway(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => setShowGiveaway(false);
    
    const handleJoinGiveaway = () => {
        navigate('/landing#form');
        handleClose();
        // Add a small delay to ensure the navigation completes
        setTimeout(() => {
            const element = document.getElementById('form');
            element?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    return (
        <>
            <HeaderComponent 
                content={HEADER_CONTENT}
            />
            <MediaMissionComponent />
            <MediaTokenBuySection />
            <Roadmap />
            <MediaTokenomics />
            <VideoSection videoSrc={vid} />
            <TweetsSection tweets={tweets} />

            <Modal
                show={showGiveaway}
                onHide={handleClose}
                centered
                className="giveaway-modal"
            >
                <Modal.Header closeButton className="bg-dark text-light border-secondary">
                    <Modal.Title>🎉 Exclusive Token Giveaway!</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark text-light">
                    <h4>Exclusive Affiliation Program</h4>
                    <p>Earn 100k tokens weekly by promoting $media with 3 posts/week.</p>
                    <ul className="mt-3">
                        <li>Join: Fill out the form on our site</li>
                        <li>Post: Share 3 $media posts weekly</li>
                        <li>Receive: Tokens awarded weekly until 20M tokens are gone</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer className="bg-dark text-light border-secondary">
                    <Button variant="secondary" onClick={handleClose}>
                        Maybe Later
                    </Button>
                    <Button variant="primary" onClick={handleJoinGiveaway}>
                        Join Giveaway
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Home;
